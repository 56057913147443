<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-if="!loggeding"
          v-bind="attrs"
          icon
          align="center"
          justify="center"
          v-on="on"
        > <v-icon>mdi-login-variant</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          align="center"
          justify="center"
        > <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
      </template>
      <v-template v-if="state === false">
        <v-card class="mx-auto" max-width="400">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="headline">
                Login
              </v-list-item-title>
              <v-list-item-subtitle
              >Login to PreMWIT2021 with Email and Password</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text>
            <v-form
              ref="login_ref"
              v-model="login_model"
            >
              <v-row align="center" justify="center">
                <v-col cols="12" sm="10" md="10">
                  <v-text-field
                    v-model="username"
                    prepend-icon="mdi-account"
                    label="Email"
                    autocomplete="username"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="10" md="10">
                  <v-text-field
                    v-model="password"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPass ? 'text' : 'password'"
                    prepend-icon="mdi-key"
                    autocomplete="current-password"
                    label="Password"
                    hint="At least 8 characters"
                    class="input-group--focused"
                    required
                    @click:append="showPass = !showPass"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-row align="center" cols="12">
                  <v-col cols="8" sm="8">
                    <v-list-item-subtitle>ถ้าไม่เคยลงทะเบียนมาก่อน<br>ให้ใช้รหัวประจำตัวผู้สอบแทนรหัสผ่าน</v-list-item-subtitle>
                  </v-col>
                  <v-col cols="2" sm="4" align="end">
                    <v-btn text @click="dologin()">
                      Login
                      <v-icon>
                        mdi-arrow-right
                      </v-icon>

                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form></v-card-text>
        </v-card>
      </v-template>
      <v-template v-if="state === 'registing'">
        <v-card>
          <v-card-title> Check mailbox</v-card-title>
        </v-card>
      </v-template>

    </v-dialog>
  </div>

</template>

<script>
import checklogin from '@/API/Security/Login.js'
import VueCookies from 'vue-cookies'
export default {
  name: 'Login',
  beforeRouteEnter (to, from, next) {
    VueCookies.keys().forEach(cookie => this.$cookies.remove(cookie))
    next()
  },
  data: () => ({
    username: '',
    password: '',
    showPass: false,
    login_model: true,
    dialog: false,
    state: false,
    loggeding: false
  }),
  mounted () {
    setInterval(function () {
      if (this.$cookies.isKey('SID') && this.$cookies.isKey('EID') && this.$cookies.isKey('GID') && this.$cookies.isKey('HID')) { this.loggeding = true } else { this.loggeding = false }
    }, 0)
  },
  methods: {
    async dologin () {
      var res = await checklogin(this.username, this.password)
      console.log(res)
      if (res === 'registing') {
        this.state = 'registing'
      } else if (res) this.$router.push({ path: '/register' })
    },
    gotoRegister () {
      this.$router.push('/register')
      // this.$router.push({ path: 'register' })
      // this.goregister = true
    },
    loggedin () {
      if (this.$cookies.isKey('SID') && this.$cookies.isKey('EID') && this.$cookies.isKey('GID') && this.$cookies.isKey('HID')) { this.loggeding = true } else { this.loggeding = false }
      console.log(this.loggeding)
    }
  }
}
</script>
