<template>
  <v-container fill-width fluid style="padding: 0 !important;">
    <v-row align="center" justify="center" cols="12">
      <v-col v-if="state === 1" cols="12" align="center">
        <Login />
      </v-col>
      <v-col cols="12" align="center" />
      <v-col v-if="state === 0" cols="12" align="center">
        <h1 id="headline">
          PreMWIT2021 Registration Open In :
        </h1>
      </v-col>
      <v-col v-if="state === 1" cols="12" align="center">
        <h1 id="headline">
          PreMWIT2021 Registration Close In :
        </h1>
      </v-col>
      <v-col v-if="state === 2" cols="12" align="center">
        <h1 id="headline">
          PreMWIT2021 Start In :
        </h1>
      </v-col>
      <br />
      <v-col cols="12" align="center">
        <div id="countdown">
          <ul>
            <li><span id="days"></span>days</li>
            <li><span id="hours"></span>Hours</li>
            <li><span id="minutes"></span>Minutes</li>
            <li><span id="seconds"></span>Seconds</li>
          </ul>
        </div>
        <div class="message">
          <div id="content">
            <span class="emoji">PreMWIT 2022</span>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Login from '@/components/Login.vue'
export default {
  name: 'Countdown',
  components: {
    Login
  },
  data: () => ({
    state: 0
  }),
  mounted () {
    this.countdown()
  },
  methods: {
    countdown () {
      const second = 1000
      const minute = second * 60
      const hour = minute * 60
      const day = hour * 24
      const regisopen = 'Dec 28, 2020 12:02:00'
      const regisclose = 'Jan 11, 2021 23:59:59'
      const campday = 'Jan 17, 2021 09:00:00'
      const regisopentime = new Date(regisopen).getTime()
      const regisclosetime = new Date(regisclose).getTime()
      const camptime = new Date(campday).getTime()

      const x = setInterval(() => {
        var now = new Date().getTime()
        var distance = 0
        if (regisopentime > now) {
          // Before Regis Waiting for Open
          distance = regisopentime - now
          this.state = 0
        } else if (regisclosetime > now) {
          // Regis open Waiting for close
          distance = regisclosetime - now
          this.state = 1
        } else if (camptime > now) {
          // Regis close Waiting for camp
          distance = camptime - now
          this.state = 2
        } else {
          // Camp ended
          distance = -1
          this.state = 3
        }

        if (
          document.getElementById('days') != null &&
          document.getElementById('hours') != null &&
          document.getElementById('minutes') != null &&
          document.getElementById('seconds') != null
        ) {
          document.getElementById('days').innerText = Math.floor(distance / day)
          document.getElementById('hours').innerText = Math.floor(
            (distance % day) / hour
          )
          document.getElementById('minutes').innerText = Math.floor(
            (distance % hour) / minute
          )
          document.getElementById('seconds').innerText = Math.floor(
            (distance % minute) / second
          )
        }

        // do something later when date is reached
        if (distance < 0) {
          const headline = document.getElementById('headline')
          const countdown = document.getElementById('countdown')
          const content = document.getElementById('content')
          // this.state = 1
          headline.innerText = 'SEE YOU AGAIN IN'
          countdown.style.display = 'none'
          content.style.display = 'block'

          console.log('End')

          clearInterval(x)
        }
        // seconds
      }, 0)
    }
  }
}
</script>

<style>
h1 {
  font-weight: normal;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

li {
  display: inline-block;
  font-size: 1.5em;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
}

li span {
  display: block;
  font-size: 4.5rem;
}

.message {
  font-size: 4rem;
}

#content {
  display: none;
  padding: 1rem;
}

@media all and (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
  }

  li {
    font-size: 1.125rem;
    padding: 0.75rem;
  }

  li span {
    font-size: 3.375rem;
  }
}
</style>
