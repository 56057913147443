import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'
import firebase from 'firebase/app'
import 'firebase/firestore'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
Vue.use(VueCryptojs)

const config = {
  apiKey: 'AIzaSyBKsIPM09FE_QY1mrkdRVJ8KZYJm83rSF0',
  authDomain: 'premwit2021.firebaseapp.com',
  databaseURL: 'https://premwit2021-default-rtdb.firebaseio.com',
  projectId: 'premwit2021',
  storageBucket: 'premwit2021.appspot.com',
  messagingSenderId: '908869500001',
  appId: '1:908869500001:web:90c4966b81e529a06a3e20',
  measurementId: 'G-F05X21FTV1'
}
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

var db = firebase.firestore()

export default async function changepassword (token, oldpwd, pwd) {
  const tdata = await db
    .collection('token')
    .doc(token)
    .get()
  if (!tdata.exists) {
    console.log("Doesn't have this token in db")
  } else {
    const encemail = tdata.data().enc
    const oldhash = await (await db.collection('userLogin').doc(encemail).get()).data().hash
    try {
      const encoldpwd = Vue.CryptoJS.MD5(oldpwd).toString()
      const encodedpwd = Vue.CryptoJS.MD5(pwd).toString()
      const sid = Vue.CryptoJS.AES.decrypt(oldhash, encoldpwd).toString(Vue.CryptoJS.enc.Utf8)
      if (sid === '') {
        return 'Current pasword is incorrect'
      }
      const hashed = Vue.CryptoJS.AES.encrypt(sid, encodedpwd).toString()
      await db
        .collection('userLogin')
        .doc(encemail)
        .set({
          hash: hashed,
          state: 'registed'
        })
      await db.collection('token').doc(token).delete()
      console.log('Successfully change password')
      return true
    } catch (e) {
      console.error(e)
      return 'Current pasword is incorrect'
    }
  }
}

// export default async function Setup (token, oldpassword, password) {
//   await changepassword(token, oldpassword, password)
// }
