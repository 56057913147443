<template>
  <v-app>
    <v-app-bar
      color="white"
      elevate-on-scroll
      fixed
      app
    >
      <v-row align="center" justify="center">
        <v-col col="12" sm="2" @click="$router.push({ name: 'Home' }).catch(err => {})">
          PreMWIT 2021
        </v-col>
        <v-spacer></v-spacer>
        <v-btn-toggle
          v-model="page"
          tile
          group
        >
          <v-btn
            v-if="notMobile()"
            @click="$router.push({ name: 'Home' }).catch(err => {})"
          >
            Home
          </v-btn>
          <v-btn
            v-if="notMobile()"
            @click="$router.push({ name: 'Schedule' }).catch(err => {})"
          >
            ตารางกิจกรรม
          </v-btn>

          <v-btn
            v-if="notMobile()"
            @click="$router.push({ name: 'Lastyear' }).catch(err => {})"
          >
            ปีที่ผ่านมา
          </v-btn>

          <v-btn
            v-if="notMobile()"
            @click="$router.push({ name: 'About' }).catch(err => {})"
          >
            เกี่ยวกับค่าย
          </v-btn>

          <v-btn
            v-if="notMobile()"
            @click="$router.push({ name: 'FAQ' }).catch(err => {})"
          >
            FAQ
          </v-btn>
          <v-btn
            v-if="$cookies.isKey('SID')"
            @click="logout"
          >
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
          <Loginbar v-if="false" align="center" justify="center" />

        </v-btn-toggle></v-row>
    </v-app-bar>

    <v-main>
      <v-container fluid style="padding: 0 !important;">
        <!-- <transition name="fade" mode="out-in" appear @after-enter="afterEnter"> -->
        <router-view></router-view>
        <!-- </transition> -->
      </v-container>
    </v-main>

    <!-- <v-footer app>
    </v-footer> -->
  </v-app>
</template>

<script>
import Loginbar from '@/components/Loginbar.vue'
export default {
  name: 'App',

  components: {
    Loginbar
  },

  data () {
    return {
      loggin: false,
      loggeding: false,
      page: true,
      state: 0
    }
  },

  watch: {},

  methods: {
    notMobile () {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // true for mobile device
        return false
      } else {
        // false for not mobile device
        return true
      }
    },
    mounted () {
      this.countdown()
      console.log(this.loggin)
      this.$ga.enable()
    },
    logout () {
      this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
      this.$router.push({ name: 'Home' })
    },
    login () {
      this.poplogin = true
    },
    loggedin () {
      if (this.$cookies.isKey('SID') && this.$cookies.isKey('HID') && this.$cookies.isKey('GID') && this.$cookies.isKey('EID')) {
        this.loggin = true
      } else {
        this.loggin = false
      }
    },
    countdown () {
      const regisopen = 'Dec 28, 2020 12:02:00'
      const regisclose = 'Jan 9, 2021 20:21:00'
      const campday = 'Jan 17, 2021 09:00:00'
      const regisopentime = new Date(regisopen).getTime()
      const regisclosetime = new Date(regisclose).getTime()
      const camptime = new Date(campday).getTime()
      this.loggedin()
      const x = setInterval(function () {
        if (this.$cookies.isKey('SID')) {
          this.loggeding = true
        } else {
          this.loggeding = false
        }
        console.log(this.loggeding)
        var now = new Date().getTime()
        var distance = 0
        if (regisopentime > now) {
          // Before Regis Waiting for Open
          distance = regisopentime - now
          this.state = 0
        } else if (regisclosetime > now) {
          // Regis open Waiting for close
          distance = regisclosetime - now
          this.state = 1
        } else if (camptime > now) {
          // Regis close Waiting for camp
          distance = camptime - now
          this.state = 2
        } else {
          // Camp ended
          distance = -1
          this.state = 3
        }

        // do something later when date is reached
        if (distance < 0) {
          this.state = 1
          clearInterval(x)
        }
        // seconds
      }, 0)
    }
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
