import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'
import firebase from 'firebase/app'
import 'firebase/firestore'
import VueCookies from 'vue-cookies'
// import logadmin from '@/API/Security/CheckAdmin.js'
import register from '@/API/Database/Register.js'
Vue.use(VueCookies)
Vue.use(VueCryptojs)

const config = {
  apiKey: 'AIzaSyBKsIPM09FE_QY1mrkdRVJ8KZYJm83rSF0',
  authDomain: 'premwit2021.firebaseapp.com',
  databaseURL: 'https://premwit2021-default-rtdb.firebaseio.com',
  projectId: 'premwit2021',
  storageBucket: 'premwit2021.appspot.com',
  messagingSenderId: '908869500001',
  appId: '1:908869500001:web:90c4966b81e529a06a3e20',
  measurementId: 'G-F05X21FTV1'
}
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

var db = firebase.firestore()
export default async function checklogin (email, pwd) {
  const encodedemail = Vue.CryptoJS.MD5(email).toString()
  const user = await db
    .collection('userLogin')
    .doc(encodedemail)
    .get()
  if (!user.exists) {
    console.error('User does not exist in credential database')
    return 'no_email'
  } else {
    const encodedHash = user.data().hash
    const encodedpwd = Vue.CryptoJS.MD5(String(pwd)).toString()
    try {
      const Sid = Vue.CryptoJS.AES.decrypt(encodedHash, encodedpwd).toString(Vue.CryptoJS.enc.Utf8)
      if (Sid === '') {
        return 'not_password'
      }
      if (user.data().state === 'no_regis') {
        var token = gentoken()
        db.collection('token').doc(token).set({ enc: encodedemail })
        await register(email, token)
        return 'registing'
      } else if (user.data().state === 'registed') {
        Vue.$cookies.set('SID', Sid, '7d')
        Vue.$cookies.set('EID', encodedemail, '7d')
        Vue.$cookies.set('HID', encodedHash, '7d')
        Vue.$cookies.set('GID', encodedpwd, '7d')
        return true
      }
    } catch (e) {
      console.error(e)
    }
  }
}

function gentoken () {
  var text = ''
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (var i = 0; i < 32; i++) { text += possible.charAt(Math.floor(Math.random() * possible.length)) }

  return text
}
