import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import password from '@/views/Password.vue'
import VerifyCookie from '@/API/Security/VerifyCookie.js'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'PreMWIT 2021 | Home',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: () => import(/* webpackChunkName: "schedule" */ '../views/Schedule.vue'),
    meta: {
      title: 'PreMWIT 2021 | Schedule',
      metaTags: [
        {
          name: 'description',
          content: 'The schedule page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The schedule page of our example app.'
        }
      ]
    }
  },
  {
    path: '/lastyear',
    name: 'Lastyear',
    component: () => import(/* webpackChunkName: "about" */ '../views/Lastyear.vue'),
    meta: {
      title: 'PreMWIT 2021 | Lastyear',
      metaTags: [
        {
          name: 'description',
          content: 'The lastyear page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The lastyear page of our example app.'
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: 'PreMWIT 2021 | About',
      metaTags: [
        {
          name: 'description',
          content: 'The about page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The about page of our example app.'
        }
      ]
    }
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "about" */ '../views/FAQ.vue'),
    meta: {
      title: 'PreMWIT 2021 | FAQ',
      metaTags: [
        {
          name: 'description',
          content: 'The faq page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The faq page of our example app.'
        }
      ]
    }
  },
  {
    path: '/fbpage',
    name: 'FBPage',
    redirect: () => {
      window.location.href = 'https://www.facebook.com/Pre-MWIT-2021-New-Challengers-104686344875273'
      return '/redirecting'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "schedule" */ '../views/Info.vue'),
    beforeEnter: async (to, from, next) => {
      try {
        if (await VerifyCookie()) {
          next()
        } else {
          next({ name: 'Home' })
        }
      } catch (err) {
        // bruh
      }
    },
    meta: {
      title: 'PreMWIT 2021 | Register',
      metaTags: [
        {
          name: 'description',
          content: 'The schedule page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The schedule page of our example app.'
        }
      ]
    }
  },
  {
    path: '/pic2020',
    name: 'Pic2020',
    redirect: () => {
      window.location.href = 'https://www.facebook.com/media/set/?vanity=MwitsMemory&set=a.1583549208461354'
      return '/redirecting'
    }
  },
  {
    path: '/108',
    name: '108',
    redirect: () => {
      window.location.href = 'https://www.108mwit.com/frontpage/?cid=popular'
      return '/redirecting'
    }
  },
  {
    path: '/password',
    name: 'Password',
    component: password,
    meta: {
      title: 'PreMWIT 2021 | Change Password',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  } // ,
  // {
  //   path: '/sandbox',
  //   name: 'Sandbox',
  //   component: () => import('../views/sandbox.vue'),
  //   meta: {
  //     title: 'PreMWIT 2021 | Change Password',
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'The home page of our example app.'
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'The home page of our example app.'
  //       }
  //     ]
  //   }
  // }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
