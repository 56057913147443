<template>
  <v-container>
    <v-card id="password">
      <Success v-if="success"></Success>

      <v-container v-if="check && !success">
        <v-card-text>
          <p id="password-title" class="display-1 text--primary">
            PASSWORD SETUP
          </p>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>
        <v-form
          ref="Password_making"
          lazy-validation
        >
          <v-row justify="center">
            <v-col v-if="incorrectpass" cols="8">
              <div style="color: red; font-size: 10px;">
                YOUR CURRENT PASSWORD IS NOT CORRECT
              </div>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="old_password"
                type="text"
                prepend-icon="mdi-key"
                placeholder="Please fill your Current password"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="password"
                type="text"
                prepend-icon="mdi-key"
                placeholder="Please fill your new password"
                outlined
                required
              ></v-text-field>
            </v-col>

            <v-col cols="8">
              <v-text-field
                v-model="c_password"
                type="text"
                prepend-icon="mdi-key"
                placeholder="Please confirm your password"
                outlined
                required
              ></v-text-field>
            </v-col>

            <v-col v-if="error" cols="8">
              <div style="color: red; font-size: 10px;">
                PLEASE CHECK YOUR PASSWORD ARE MATCHED
              </div>
            </v-col>

            <v-col cols="6">
              <v-btn
                block
                color="green"
                class="ma-2 white--text"
                @click="submit()">
                <v-icon>mdi-check-bold</v-icon>
                CONFIRM
              </v-btn>
            </v-col>

          </v-row>
        </v-form>
      </v-container>

      <v-container v-if="!check && !success">
        <v-row justify="center" align="center">
          <v-col id="error" cols="12" sm="4">
            THERE IS AN <a id="red_error">
              ERROR
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>

</template>

<script>
import checkToken from '@/API/Database/FetchToken.js'
import Setup from '@/API/Database/SetupPassword.js'
import Success from '@/components/PasswordSuccess.vue'
export default {
  name: 'Changepass',
  components: {
    Success
  },
  data: () => ({
    token: '',
    check: '',
    old_password: '',
    password: '',
    c_password: '',
    error: '',
    success: false,
    incorrectpass: false
  }),
  mounted () {
    this.getToken()
    this.trueToken()
  },
  methods: {
    getToken () {
      this.token = this.$route.query.token
    },
    async trueToken () {
      this.check = await checkToken(this.token)
    },
    async submit () {
      this.error = false
      this.incorrectpass = false
      if (this.password !== this.c_password) {
        this.error = true
      } else {
        const res = await Setup(this.token, this.old_password, this.password)
        if (res === true) {
          this.success = true
        } else if (res === 'Current pasword is incorrect') {
          this.error = false
          this.incorrectpass = true
        } else {
          console.error(res)
        }
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;500&display=swap');

#password {
    width: 90%;
    left: 5%;
}

#password_title{
  font-family: 'Kanit', sans-serif;
  font-weight: 500;
}

#error {
  font-family: 'Kanit', sans-serif;
  font-weight: 500;
  font-size: 30px;
}
#red_error {
  color: red
}
</style>
