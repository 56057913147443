<template>
  <div id="container">
    <v-row>
      <v-col cols="12" align="center" justify="center">
        <v-icon size="100" color="#4CAF50">
          mdi-check-circle-outline
        </v-icon>
      </v-col>

      <v-col cols="12" align="center" justify="center">
        <div id="thank">
          THANK YOU!
        </div>
      </v-col>
      <v-col cols="12" align="center" justify="center">
        <div id="mes">
          Your registration is successful and you can <a @click="$router.push(path = 'login')">login</a> now.
        </div>
      </v-col>
      <v-col cols="12" align="center" justify="center">
        <v-btn
          class="ma-2 white--text"
          color="#4CAF50"
          style="font-family: 'Kanit', sans-serif; color: white;"
          @click="login()"
        >
          <v-icon>mdi-login</v-icon>
          Login
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Passwordchanged',
  methods: {
    login () {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;500&display=swap');

#container {
    position: absolute;
    height: 60%;
    top: 20%;
    width: 100%;
}

#thank {
    font-family: 'Kanit', sans-serif;
    font-size: 300%;
    font-weight: 500;
}

#mes {
    font-family: 'Kanit', sans-serif;
    font-size: 150%;
    font-weight: 200;
}

</style>
