import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
Vue.use(VueCryptojs)

const config = {
  apiKey: 'AIzaSyBKsIPM09FE_QY1mrkdRVJ8KZYJm83rSF0',
  authDomain: 'premwit2021.firebaseapp.com',
  databaseURL: 'https://premwit2021-default-rtdb.firebaseio.com',
  projectId: 'premwit2021',
  storageBucket: 'premwit2021.appspot.com',
  messagingSenderId: '908869500001',
  appId: '1:908869500001:web:90c4966b81e529a06a3e20',
  measurementId: 'G-F05X21FTV1'
}
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}
var db = firebase.firestore()
export default async function logadmin (email, pwd) {
  const localstring = email + 'aLuMn1' + pwd
  const localkey = Vue.CryptoJS.MD5(localstring).toString()
  const admin = await db
    .collection('logadmin')
    .doc(localkey)
    .get()
  if (!admin.exists) {
    return false
  }
  const dbload = admin.data()
  const dbsalt = dbload.salt
  const sha1 = Vue.CryptoJS.SHA1(dbsalt + pwd + email).toString()
  const sha256 = Vue.CryptoJS.SHA256(email + pwd + dbsalt).toString()
  const sha384 = Vue.CryptoJS.SHA384(email + dbsalt).toString()
  const sha512 = Vue.CryptoJS.SHA512(pwd + dbsalt).toString()
  if (sha512 === dbload.sha512 && sha256 === dbload.sha256 && sha384 === dbload.sha384) {
    Vue.$cookies.set('SID', sha1, '7d')
    Vue.$cookies.set('EID', sha256, '7d')
    Vue.$cookies.set('GID', sha384, '7d')
    await Vue.$cookies.set('HID', sha512, '7d')
    return true
  } else return false
}

export function verifyadmin () {
  try {
    const SID = Vue.$cookies.get('SID').length === 40
    const EID = Vue.$cookies.get('EID').length === 64
    const GID = Vue.$cookies.get('GID').length === 96
    const HID = Vue.$cookies.get('HID').length === 128
    if (SID && EID && GID && HID) return true
    else {
      return false
    }
  } catch (e) {
    return false
  }
}

export async function checkadmin () {
  const SID = Vue.$cookies.get('SID')
  const EID = Vue.$cookies.get('EID')
  const GID = Vue.$cookies.get('GID')
  const HID = Vue.$cookies.get('HID')
  try {
    const admin = await db
      .collection('keepadmin')
      .doc(SID)
      .get()
    if (!admin.exists) {
      Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie))
      alert('You are not authorized to access this page. This incident will be reported along with your IP')
      return false
    } else {
      const DBload = admin.data()
      try {
        if (verifyadmin()) {
          if (EID === DBload.sHa2S6 && GID === DBload.Sha38A && HID === DBload.sh45i2) {
            return true
          } else {
            Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie))
            alert('You are not authorized to access this page. This incident will be reported along with your IP')
            return false
          }
        } else {
          Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie))
          alert('You are not authorized to access this page. This incident will be reported along with your IP')
          return false
        }
      } catch (e) {
        Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie))
        return false
      }
    }
  } catch (e) {
    Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie))
    return false
  }
}
