import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'
// import firebase from 'firebase/app'
// import 'firebase/firestore'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
Vue.use(VueCryptojs)

// const config = {
//   apiKey: 'AIzaSyBKsIPM09FE_QY1mrkdRVJ8KZYJm83rSF0',
//   authDomain: 'premwit2021.firebaseapp.com',
//   databaseURL: 'https://premwit2021-default-rtdb.firebaseio.com',
//   projectId: 'premwit2021',
//   storageBucket: 'premwit2021.appspot.com',
//   messagingSenderId: '908869500001',
//   appId: '1:908869500001:web:90c4966b81e529a06a3e20',
//   measurementId: 'G-F05X21FTV1'
// }
// if (!firebase.apps.length) {
//   firebase.initializeApp(config)
// }

// var db = firebase.firestore()

// function genToken () {
//   var result = ''
//   var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
//   var charactersLength = characters.length
//   for (var i = 0; i < 32; i++) {
//     result += characters.charAt(Math.floor(Math.random() * charactersLength))
//   }
//   return result
// }

function sendemail (email, token) {
  var xhr = new XMLHttpRequest()
  // xhr.open('POST', 'http://localhost:6969/sendmail?dest=' + email + '&token=' + token, true)
  xhr.open('POST', 'http://2021.premwit.com:6969/sendmail?dest=' + email + '&token=' + token, true)
  // xhr.setRequestHeader('Access-Control-Allow-Origin', 'http://2021.premwit.com:8080')
  xhr.setRequestHeader('Access-Control-Allow-Origin', 'http://2021.premwit.com:80')
  // xhr.setRequestHeader('Access-Control-Allow-Origin', 'http://localhost:8080')
  xhr.setRequestHeader('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS')
  xhr.setRequestHeader('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token')
  xhr.send()
  console.log('Email has been sended')
}

// async function checkrequested (enmail) {
//   const requests = await db
//     .collection('requests')
//     .doc(enmail)
//     .get()
//   if (requests.exists) {
//     throw new Error('Already Registered')
//   }
// }

export default async function main (email, token) {
  await sendemail(email, token)
}
