import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'
import VueCookies from 'vue-cookies'
import { verifyadmin } from '@/API/Security/CheckAdmin.js'
Vue.use(VueCookies)
Vue.use(VueCryptojs)

export default async function VerifyCookie () {
  if (!Vue.$cookies.isKey('SID')) {
    Vue.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
    return false
  }
  const SID = Vue.$cookies.get('SID')
  const HID = Vue.$cookies.get('HID')
  const GID = Vue.$cookies.get('GID')
  if (verifyadmin()) {
    return true
  }
  try {
    const VSID = Vue.CryptoJS.AES.decrypt(HID, GID).toString(Vue.CryptoJS.enc.Utf8)
    if (VSID === SID) {
      return true
    } else {
      Vue.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
      alert('Please do not hack my cutie website (Fuckoff dam Hacker)')
      return false
    }
  } catch (e) {
    Vue.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
    return false
  }
}
