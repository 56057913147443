<template>
  <div class="home">
    <v-container>
      <v-row>
        <v-img style="margin-top:150px; margin-bottom:0px" src="../assets/Logo.webp" max-height="305" contain></v-img>
      </v-row>
    </v-container>

    <v-container>
      <v-row no-gutters>
        <v-col cols="12" class="text-center">
          <div style="margin-top: 10px; margin-bottom: 25px">
            <span class="hello text-h2">PreMWIT 2021 </span>
            <span class="hello typed-text text-h2"></span>
            <span class="hello cursor text-h2"> .</span>
            <br />
            <span class="text-h5">ยินดีต้อนรับสู่โลกแห่งเวทมนตร์</span>
            <br />
            <span class="text-h5"
            >" วิ ท ย า ศ า ส ต ร์ คื อ เ ว ท ม น ต ร์ ที่ มี อ ยู่ จ ริ ง
              "</span
            >
          </div>
        </v-col>

        <v-col cols="12" class="text-center">
          <Countdown />
        </v-col>
      </v-row>
    </v-container>

    <v-container fill-width fluid style="padding: 0 !important">
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <div
            class="img_container"
            @click="$router.push({ name: 'Schedule' })"
          >
            <v-parallax
              :src="require('../assets/Home/home1.jpg')"
              height="350"
              class="image"
            ></v-parallax>
            <div class="centered text-h3">
              ตารางกิจกรรม
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="6">
          <div
            class="img_container"
            @click="$router.push({ name: 'Lastyear' })"
          >
            <v-parallax
              :src="require('../assets/Home/home2.jpg')"
              height="350"
              class="image"
            ></v-parallax>
            <div class="centered text-h3">
              ปีที่ผ่านมา
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container fill-width fluid style="padding: 0 !important">
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <div class="img_container" @click="$router.push({ name: 'About' })">
            <v-parallax
              :src="require('../assets/Home/home3.jpg')"
              height="350"
              class="image"
            ></v-parallax>
            <div class="centered text-h3">
              เกี่ยวกับค่าย
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="6">
          <div class="img_container" @click="$router.push({ name: 'FAQ' })">
            <v-parallax
              :src="require('../assets/Home/home4.jpg')"
              height="350"
              class="image"
            ></v-parallax>
            <div class="centered text-h3">
              คำถามที่พบบ่อย
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="8" class="text-center">
          <div style="margin-top: 100px">
            <span class="text-h4">สิ่งที่ต้องเตรียม</span>
          </div>
        </v-col>
        <v-col cols="12" sm="6" class="text-center">
          <div style="margin-top: 20px; margin-bottom: 100px">
            <span class="text-h6 bring" style="font-weight: 300"
            >• Laptop หรือ Computer ที่มีกล้อง</span
            >
            <br />
            <span class="text-h6 bring" style="font-weight: 300"
            >• โปรแกรม Discord</span
            >
            <br />
            <span class="text-h6 bring" style="font-weight: 300"
            >• Internet Ping ต่ำ</span
            >
            <br />
            <span class="text-h6 bring" style="font-weight: 300"
            >• ห้องเงียบๆ</span
            >
            <br />
            <span class="text-h6 bring" style="font-weight: 300"
            >*** เ ต รี ย ม ตั ว เ ต รี ย ม ใ จ ***</span
            >
            <br />
            <br />

          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container fill-width fluid style="padding: 0 !important">
      <div class="img_container_footer" @click="$router.push({ name: 'FBPage' })">
        <v-parallax
          :src="require('../assets/Home/homeT.jpg')"
          style="filter: brightness(50%);"
          height="600"
        ></v-parallax>
        <div class="centered">
          <span class="text-h3">ติดตามข่าวสารกิจกรรม</span><br><span class="text-h5"><v-icon color="blue" large>{{ mdiFacebook }}</v-icon>PreMWIT2021 : New Challenges</span>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Countdown from '@/components/Countdown.vue'
// import Login from '@/components/Login.vue'

import { mdiFacebook } from '@mdi/js'

export default {
  name: 'Home',
  web: 'https://',

  components: {
    Countdown
    // Login
  },

  data: () => ({
    drawer: false,
    group: null,
    mdiFacebook,

    items: [
      {
        text: 'PreMWIT2021 : New Challenges',
        disabled: false,
        href:
          'https://www.facebook.com/Pre-MWIT-2021-New-Challengers-104686344875273'
      }
    ],

    itemss: [
      {
        id: 'part',
        src:
          'https://www.facebook.com/MwitsMemory/photos/a.1583549208461354/1583549575127984',
        title: 'rack'
      }
    ]
  }),

  mounted () {
    this.text_anim()
  },

  methods: {
    text_anim () {
      const typedTextSpan = document.querySelector('.typed-text')
      const cursorSpan = document.querySelector('.cursor')

      const textArray = ['สวัสดีจ้า', 'ยินดีต้อนรับ']
      const typingDelay = 200
      const erasingDelay = 100
      const newTextDelay = 1000 // Delay between current and next text
      let textArrayIndex = 0
      let charIndex = 0

      function type () {
        if (charIndex < textArray[textArrayIndex].length) {
          if (!cursorSpan.classList.contains('typing')) { cursorSpan.classList.add('typing') }
          typedTextSpan.textContent += textArray[textArrayIndex].charAt(
            charIndex
          )
          charIndex++
          setTimeout(type, typingDelay)
        } else {
          cursorSpan.classList.remove('typing')
          setTimeout(erase, newTextDelay)
        }
      }

      function erase () {
        if (charIndex > 0) {
          if (!cursorSpan.classList.contains('typing')) { cursorSpan.classList.add('typing') }
          typedTextSpan.textContent = textArray[textArrayIndex].substring(
            0,
            charIndex - 1
          )
          charIndex--
          setTimeout(erase, erasingDelay)
        } else {
          cursorSpan.classList.remove('typing')
          textArrayIndex++
          if (textArrayIndex >= textArray.length) textArrayIndex = 0
          setTimeout(type, typingDelay + 1100)
        }
      }

      document.addEventListener('DOMContentLoaded', function () {
        // On DOM Load initiate the effect
        if (textArray.length) setTimeout(type, newTextDelay + 250)
      })
    }
  }
}
</script>

<style scoped>
  .hello {
    font-weight: 400;
  }

  .home {
    width: 100%;
    position: absolute;
    top: 100;
    left: 0;
    background-size: cover;;
    background: url('/assets/BG/bghome.webp') center;
    background-repeat: repeat-y;
    background-color: white;
    transform: scale(1.1);
  }

.cursor {
  display: inline-block;
  background-color: #000;
  margin-left: 0.1rem;
  width: 3px;
  animation: blink 1s infinite;
}

.cursor.typing {
  animation: none;
}

.text-h7 {
  font-weight: 200 !important;
}

@keyframes blink {
  0% {
    background-color: #000;
  }
  49% {
    background-color: #000;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
  100% {
    background-color: #000;
  }
}

.img_container {
  position: relative;
  text-align: center;
  color: white;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
  filter: blur(0px);
  filter: brightness(50%);
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  transition: color 0.8s;
  font-weight: 500 !important;
}

.img_container:hover .image {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: url(#blur);
  filter: blur(4px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='2');
  -webkit-transition: 0.5s -webkit-filter linear;
  -o-transition: 0.5s -o-filter linear;
  filter: brightness(90%);
}

.img_container:hover .centered {
  opacity: 1;
  text-decoration: underline;
}

.bring {
  font-weight: 400;
}

.img_container_footer {
  position: relative;
  text-align: center;
  color: white;
}

.img_container_footer:hover .centered {
  text-decoration: underline;
}
</style>
